import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { getConfig } from '../config/config';
import { WEB_CLIENT_NEXT_URL, WEB_CLIENT_URL } from '../config/constants';
/**
 * Fetches the integration configuration
 * @param host the channels host
 * @param id the integration id
 * @returns
 */
export const fetchIntegration = async (id, host) => {
    const channelHost = host || getConfig().CHANNELS_HOST;
    const response = await fetch(`${channelHost}/v1/web/${id}`);
    const text = await response.text();
    if (response.ok) {
        const data = JSON.parse(text);
        return data;
    }
    throw new Error(`Error loading config from: ${channelHost}/${id}`);
};
/**
 * Promise that resolves when the document is ready.
 *
 */
export const onDocumentReady = () => new Promise((resolve) => {
    if (document.readyState === 'complete') {
        return resolve();
    }
    else {
        window.addEventListener('load', () => {
            return resolve();
        });
    }
});
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const getTimestamp = () => Date.now();
export const KEYS = {
    UP: 'ArrowUp',
    DOWN: 'ArrowDown',
    ESC: 'Escape',
    ENTER: 'Enter',
    DELETE: 'Delete',
    SPACE: 'Space',
    TAB: 'Tab',
};
export const emailRegExp = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))){2,}$/i;
export const validateYouTubeUrl = (text) => {
    if (text) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        const match = text.match(regExp);
        if (match && match[2].length == 11) {
            return `https://www.youtube.com/embed/${match[2]}?autoplay=0`;
        }
    }
    return false;
};
export const isWindowMobile = () => window.innerWidth < 650 ||
    (window.innerHeight < 550 && window.innerWidth < 1000);
/**
 * Returns the URL that will be use to load the iframe
 */
export const createIframeUrl = (widgetId) => {
    // 1. Check if using the beta/next release by looking at the script src
    let url = '';
    const scripts = document.querySelectorAll('script');
    const regex = /@moveo-ai\/web-client.*next/;
    if (scripts) {
        scripts.forEach((script) => {
            var _a;
            if ((_a = script.src) === null || _a === void 0 ? void 0 : _a.match(regex)) {
                url = WEB_CLIENT_NEXT_URL;
            }
        });
    }
    // 2. Check if using localhost, specific moveo-ai deployment on Vercel,
    // or other conditions by looking at the origin
    const { origin } = window.location;
    if (!url) {
        if (origin.match(/localhost:8880/) ||
            origin.match(/web-client.*moveo-ai\.vercel\.app/)) {
            url = origin; // Load from localhost or a web-client deployment in Vercel
        }
        else if (origin.match(/web-client\.dev/)) {
            url = WEB_CLIENT_NEXT_URL; // Specific web-client development URL
        }
        else {
            url = WEB_CLIENT_URL; // Default to the production web-client URL
        }
    }
    // 4. Append the WidgetId to the iframe so that this window and the iframe can
    // stablish a communication channel
    return `${url}?widgetId=${widgetId}`;
};
export const parseEvent = (widgetId, event) => {
    var _a, _b;
    const eventType = (_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.type;
    if (typeof eventType !== 'string' || !eventType.startsWith(widgetId)) {
        return null;
    }
    // Extract the event type by removing the prefix `widgetId`
    const type = eventType.replace(widgetId, '');
    const data = (_b = event.data) === null || _b === void 0 ? void 0 : _b.data;
    return { type, data };
};
export const isTest = (parentUrl) => {
    if (parentUrl) {
        const url = new URL(parentUrl).origin;
        if (url.match(/localhost:|vercel.app/) ||
            url === WEB_CLIENT_NEXT_URL ||
            url === WEB_CLIENT_URL) {
            return true;
        }
    }
    return false;
};
function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
}
// From: stackoverflow.com/a/37164538
export function mergeDeep(target = {}, source = {}) {
    const output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, { [key]: source[key] });
                else
                    output[key] = mergeDeep(target[key], source[key]);
            }
            else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}
export const scrollToElement = (element) => {
    scrollIntoView(element, {
        behavior: 'smooth',
        inline: 'end',
        block: 'end',
    });
};
